import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import qs from 'querystring';
import { useDebounceCallback } from '@react-hook/debounce';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { PencilFill } from 'react-bootstrap-icons';
import capitalize from 'capitalize-first-letter';
import { useDropzone } from 'react-dropzone';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import mixpanel from 'mixpanel-browser';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import DateRangeControl from '../components/DateRangeControl';
import DriversWorking from './DriversWorking';
import ProcessSOO from '../fileHandlers/ProcessSOO';
import ProgressModal from '../components/ProgressModal';
import apiRoot from '../constants/apiRoot';

const { ExportCSVButton } = CSVExport;

const filterHeaderFormatter = (
  column,
  colIndex,
  { sortElement, filterElement },
) => (
  <div>
    <div>
      {column.text}
      {sortElement}
    </div>
    <div>{filterElement}</div>
  </div>
);

const columns = [
  {
    dataField: 'edit',
    text: '',
    isDummyField: true,
    csvExport: false,
    headerStyle: { width: '50px' },
    formatter: (cell, row) => (
      <Button
        variant="primary"
        size="sm"
        block
        onClick={() => {
          mixpanel.track('Open Card', {id: row.id, trailer: row.trailer, status: row.status});
          window.postMessage({ action: 'FETCH_MOVE', id: row.id }, '*');
        }}
      >
        <div className="d-flex justify-content-center">
          <PencilFill />
        </div>
      </Button>
    ),
  },
  {
    dataField: 'id',
    hidden: true,
    csvExport: false,
  },
  {
    dataField: 'trailer',
    text: 'Trailer',
    headerClasses: 'trailer',
    headerFormatter: filterHeaderFormatter,
    filter: textFilter(),
    sort: true,
    formatter: (cell, row) => (
      <div>
        <div className="trailer">{cell}</div>
        {row.is_ups &&
          <img height={22} src="/ups.png" alt="" />
        }

        {row.is_haz &&
          <img height={22} src="/haz.png" alt="" />
        }

        {row.is_bad &&
          <img height={22} src="/redtag.png" alt="" />
        }
      </div>
    ),
  },
  {
    dataField: 'type',
    text: 'Type',
    headerFormatter: filterHeaderFormatter,
    headerClasses: 'narrow',
    sort: true,
    filter: selectFilter({
      options: {
        LOAD: 'Load',
        EMPTY: 'Empty',
        DOOR: 'Door',
      },
    }),
    formatter: (cell) => capitalize(cell.toLowerCase()),
    hidden: true
  },
  {
    dataField: 'status',
    text: 'Status',
    headerFormatter: filterHeaderFormatter,
    headerClasses: 'narrow',
    sort: true,
    filter: selectFilter({
      options: {
        RECEIVED: 'Received',
        ARRIVED: 'Arrived',
        GROUNDED: 'Grounded',
        ASSIGNED: 'Assigned',
        DELIVERED: 'Delivered',
        CANCELED: 'Canceled',
        ACCESSORIAL: 'Accessorial'
      },
    }),
    formatter: (cell) => capitalize(cell.toLowerCase()),
  },
  {
    dataField: 'to',
    text: 'To',
    headerFormatter: filterHeaderFormatter,
    headerClasses: 'narrow',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'from',
    text: 'From',
    headerFormatter: filterHeaderFormatter,
    headerClasses: 'narrow',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'shipper',
    text: 'Shipper',
    headerFormatter: filterHeaderFormatter,
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'destination',
    text: 'Destination',
    headerFormatter: filterHeaderFormatter,
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'pickup_number',
    text: 'PU#',
    headerFormatter: filterHeaderFormatter,
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'ref',
    text: 'Ref #',
    headerFormatter: filterHeaderFormatter,
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'temp',
    text: 'Temp',
    headerFormatter: filterHeaderFormatter,
    headerClasses: 'narrow',
    sort: true,
  },
  {
    dataField: 'grounded_time',
    text: 'Grounded',
    headerFormatter: filterHeaderFormatter,
    sort: true,
  },
  {
    dataField: 'location',
    text: 'Location',
    headerFormatter: filterHeaderFormatter,
    headerClasses: 'narrow',
    sort: true,
    csvExport: false
  },
  {
    dataField: 'driver',
    text: 'Driver',
    headerFormatter: filterHeaderFormatter,
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'assigned_time',
    text: 'Assigned',
    headerFormatter: filterHeaderFormatter,
    sort: true,
  },
  {
    dataField: 'shift_time',
    text: 'Shift Time',
    hidden: true,
  },
  {
    dataField: 'priority',
    text: 'Priority',
    headerFormatter: filterHeaderFormatter,
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'trailer_size',
    text: 'Trailer Size',
    headerFormatter: filterHeaderFormatter,
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'is_haz',
    text: 'Hazmat',
    hidden: true
  },
  {
    dataField: 'rate',
    text: 'Rate',
    headerFormatter: filterHeaderFormatter,
    hidden: true,
  },
  {
    dataField: 'cutoff',
    text: 'Quick Note',
    headerFormatter: filterHeaderFormatter,
    sort: true,
  },
  {
    dataField: 'notes',
    text: 'Notes',
    hidden: true,
  },
];

const defaultSorted = [{
  dataField: 'status',
  order: 'desc',
}];

let abortController = null;
let isSearching = false;

const Search = () => {
  const [moves, setMoves] = useState([]);
  const [fileProgress, setFileProgress] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  const searchForm = useRef();

  const search = useDebounceCallback((term, from, to, showDelivered = true) => {
    abortController = new AbortController();
    isSearching = true;
    setErrorMessage(false);
    setMoves([]);

    mixpanel.track('Search', {term, from, to});

    fetch(`${apiRoot}/search/moves?${qs.encode({
      term,
      shift_time_from: from,
      shift_time_to: to,
      show_delivered: showDelivered
    })}`, {
      signal: abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => {
        isSearching = false;
        setMoves(data.message);
      })
      .catch((err) => {
        // rethrow everything but AbortError
        if (err.name !== 'AbortError') {
          setErrorMessage(true);
          isSearching = false;
          throw err;
        }
      });
  }, 300);

  const handleSearch = useCallback((showDelivered = true) => {
    if (isSearching) {
      abortController.abort();
    }

    const formData = new FormData(searchForm.current);
    search(formData.get('term'), formData.get('start_date'), formData.get('end_date'), showDelivered);
  }, [search]);

  useEffect(() => {
    handleSearch(false);
  }, [handleSearch]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleSearch();
  };

  // Set priorities
  // @todo This needs to be a shared util file because it is used in dashboard and in internal
  Object.keys(moves).forEach((i) => {
    if (!moves[i].priority) {
      moves[i].priority = 4;
      moves[i].priorityAutomatic = true;

      if (moves[i].is_ups) {
        moves[i].priority = 1;
      }

      if (moves[i].grounded_time) {
        const diff = moment().diff(
          moment(moves[i].grounded_time),
          'hours',
        );

        if (diff >= 36) {
          moves[i].priority = 1;
        } else if (diff >= 24) {
          moves[i].priority = 2;
        } else if (diff >= 12) {
          moves[i].priority = 3;
        }
      }

      if (moves[i].is_bad) {
        moves[i].priority = 4;
      }
    }
  });

  const formatTime = (time) => {
    return moment(time).format('YYYY-MM-DD HH:mm');
  };

  const data = moves.map((move) => ({
    id: move.id,
    type: move.type,
    status: move.status,
    trailer: move.trailer || '',
    to: move.to || '',
    from: move.from || '',
    shipper: move.shipper || '',
    destination: move.destination || '',
    rate: move.rate || '',
    pickup_number: move.pickup_number || '',
    ref: move.ref || '',
    cutoff: move.cutoff || '',
    temp: move.temp || '',
    grounded_time: (move.grounded_time) ?
      formatTime(move.grounded_time) : '',
    location: move.location,
    driver: (move.driver && move.driver.nick_name) || '',
    assigned_time: (move.assigned_time) ?
      formatTime(move.assigned_time) : '',
    is_ups: (move.is_ups && 'Yes') || '',
    is_haz: (move.is_haz && 'Yes') || '',
    is_bad: (move.is_bad && 'Yes') || '',
    trailer_size: move.trailer_size || '',
    priority: move.priority || '',
    notes: move.description || '',
    shift_time: (move.shift_time) ?
      formatTime(move.shift_time) : '',
  }));

  const rowClasses = (row) => {
    return row.type;
  };

  const driversWorking = (
    <Popover id="drivers-working-popover">
      <Popover.Content>
        <DriversWorking />
      </Popover.Content>
    </Popover>
  );

  const onDrop = useDebounceCallback((acceptedFiles) => {
    setFileProgress([]);
    if (acceptedFiles.length > 1) {
      return;
    }

    const file = acceptedFiles[0];
    let promise;

    if (file.name.search('Crosstown_units_for_CNRP') > -1) {
      promise = ProcessSOO(file, 'SCHILLER');
    } else if (file.name.search('CNRP_53 S_ONLY') > -1) {
      promise = ProcessSOO(file, 'SOO');
    }

    if (promise) {
      promise.progress((message) => {
        setFileProgress((fp) => [...fp, message]);
      });
    }
  }, 1000);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className="searchMain"
      {...getRootProps(
        {
          ...getRootProps({
            onClick: (ev) => {
              ev.stopPropagation();
            },
          }),
        },
      )}
    >
      <input {...getInputProps()} />

      {isDragActive && (
        <div className="dropZone">
          <div>Drop your file here</div>
        </div>
      )}

      {fileProgress.length > 0 &&
        <ProgressModal progress={fileProgress} />
      }

      <Row>
        <Col>
          <div className="topRow">
            <Form className="search mt-2" inline onSubmit={handleSubmit} ref={searchForm}>
              <FormControl
                name="term"
                placeholder="Search Trailer #"
                className="my-1 mr-sm-2"
              />
              <DateRangeControl
                className="my-1 mr-2"
              />
              <Button
                variant="primary"
                type="submit"
              >
                Search
              </Button>
            </Form>

            <div className="driversWorking mt-2">
              <OverlayTrigger trigger="click" placement="left" overlay={driversWorking}>
                <Button variant="success">Drivers</Button>
              </OverlayTrigger>
            </div>
          </div>

          {!!errorMessage &&
            <div style={{textAlign: 'center'}}>
              <hr/>
              <h4>Something went wrong.</h4>
              <br />
              <div>
                If you are trying to search, try setting a smaller date range.<br />
                If you would like to see all active moves, please refresh the page.<br />
                If you continue to see this message, please contact tech support.
              </div>
            </div>
          }

          {!errorMessage && moves.length <= 0 &&
            <div style={{textAlign: 'center'}}>
              <hr/>
              <Spinner animation="border" variant="primary" />
            </div>
          }

          {moves.length > 0 &&
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              exportCSV={{
                exportAll: false,
                onlyExportFiltered: true,
              }}
            >
              {
                (props) => (
                  <div>
                    <ExportCSVButton {...props.csvProps} className="exportButton">
                      Export
                      {' '}
                      <img
                        height="18"
                        alt="Excel Logo"
                        src="./excel.svg"
                      />
                    </ExportCSVButton>
                    <hr/>
                    <BootstrapTable
                      // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
                      {...props.baseProps}
                      keyField="id"
                      data={data}
                      columns={columns}
                      pagination={paginationFactory()}
                      noDataIndication={() => <div>No Results</div>}
                      filter={filterFactory()}
                      defaultSorted={defaultSorted}
                      rowClasses={rowClasses}
                      responsive
                      hover
                      bootstrap4
                      condensed
                    />
                  </div>
                )
              }
            </ToolkitProvider>
          }
        </Col>
      </Row>
    </div>
  );
};

export default Search;
