import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Search from './routes/Search';
import Drivers from './routes/Drivers';
import DriversWorking from './routes/DriversWorking';
import Terminals from './routes/Terminals';
import Logs from './routes/Logs';
import MicrosoftAuthResponse from './routes/MicrosoftAuthResponse';
import MicrosoftAuth from './routes/MicrosoftAuth';
import mixpanel from 'mixpanel-browser';

const App = () => {
  mixpanel.init('ca26afd7f3eccd7cd60591010fa2417e', {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  });

  return (
    <Router>
      <Container fluid>
        <div style={{
            backgroundColor: 'white',
            color: 'black',
            padding: '10px',
            textAlign: 'center',
            fontSize: '20px',
            position: 'absolute',
            width: '100%',
            height: '50px',
            top: 0,
            left: 0,
            zIndex: 1000,
            borderBottom: '1px solid #c0c0c0'
        }}>
            Important: Due to policy changes from Google, this site will stop functioning in June 2025. <a href="https://dispatch.centeratransport.com/help/legacy-dashboard-end-of-life-faq" target="_blank" rel="noreferrer">Learn more</a>
        </div>

        <div style={{marginTop: '50px'}}>          
          <Switch>
            <Route exact path="/" component={Search} />
            <Route path="/search" component={Search} />
            <Route path="/drivers" component={Drivers} />
            <Route path="/terminals" component={Terminals} />
            <Route path="/driversworking" component={DriversWorking} />
            <Route path="/logs" component={Logs} />
            <Route path="/microsoft-auth" component={MicrosoftAuth} />
            <Route path="/microsoft-auth-response" component={MicrosoftAuthResponse} />
          </Switch>
        </div>
      </Container>
    </Router>
  );
};

export default App;
